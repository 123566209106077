<template>
    <!-- Buitenzijde (A) -->
    <div class="flex flex-wrap relative" :class="{ 'error-step': invalid }">
        <div v-if="disabled" class="z-20 inset-0 bg-white bg-opacity-50 absolute transition-all"></div>
        <div class="w-1/2">
            <Listbox as="div" v-model="selectedA" v-slot="{ open }">
                <ListboxLabel class="block text-sm font-bold text-gray-700 mb-1" :class="{ 'block md:hidden': (index > 0 && mks) || wizard }"> Buitenzijde </ListboxLabel>
                <div class="relative">
                    <ListboxButton
                        class="bg-white relative w-full border border-gray-300 rounded-l-md shadow-sm pl-4 pr-8 text-left cursor-pointer focus:outline-none hover:shadow-md duration-700"
                        :class="{
                            'border-red-500 ': invalid && !valueA,
                            'border-dgreen pulse': index == focusIndex && !valueA && !open,
                            'py-3': categoryFilter,
                            'py-4': !categoryFilter,
                        }"
                    >
                        <span v-if="selectedA && !disabled" class="block truncate">{{ selectedA }} mm</span>
                        <span v-else class="block">...</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <fa :icon="['fal', 'angle-down']" class="h-5 w-5 text-gray-400" />
                        </span>
                    </ListboxButton>

                    <!-- <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0"> -->
                    <transition name="drop">
                        <ListboxOptions
                            class="absolute z-30 bg-white shadow-lg max-h-[380px] md:max-h-[490px] -mt-16 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none w-[230px]"
                        >
                            <ListboxOption as="template" v-for="size in sizesA" :key="size" :value="size" v-slot="{ active, selected }">
                                <li
                                    :class="[selected || active ? 'bg-gray-100' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-4 border-t border-gray-100']"
                                    @click="
                                        $emit('update:valueA', size);
                                        updateOptionsSizeB(size);
                                    "
                                >
                                    <div class="flex items-center space-x-2">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']"> {{ size }}<span class="text-gray-600 text-sm ml-1">mm</span> </span>
                                        <span v-if="size == 10 || size == 9" class="text-gray-600 block text-xs">Halve cilinder</span>
                                        <span v-else-if="(product && product.brand_id == 1) || mks" class="text-gray-400 block text-xs">(past ook op {{ size - 2 }} mm)</span>
                                    </div>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>
        </div>

        <div class="w-1/2 right-column -ml-[1px]" :class="{ 'opacity-50': !sizesB.length && !selectedB }">
            <!-- Binnenzijde (A) -->
            <Listbox as="div" v-model="selectedB">
                <ListboxLabel class="block text-sm font-bold text-gray-700 mb-1" :class="{ 'block md:hidden': (index > 0 && mks) || wizard }"> Binnenzijde </ListboxLabel>
                <div class="relative">
                    <div v-if="!sizesB.length && !selectedB" class="z-20 absolute inset-0 cursor-not-allowed"></div>

                    <ListboxButton
                        class="bg-white relative w-full border border-gray-300 rounded-r-md shadow-sm pl-4 pr-8 text-left cursor-pointer focus:outline-none hover:shadow-md duration-700"
                        :class="{
                            'border-red-500': invalid && !valueB,
                            'border-dgreen pulse': index == focusIndex && valueA && !valueB,
                            'py-3': categoryFilter,
                            'py-4': !categoryFilter,
                        }"
                    >
                        <span v-if="selectedB && !disabled" class="block truncate">{{ selectedB }} mm</span>
                        <span v-else class="block truncate">...</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <fa :icon="['fal', 'angle-down']" class="h-5 w-5 text-gray-400" />
                        </span>
                    </ListboxButton>

                    <transition name="drop">
                        <ListboxOptions
                            class="absolute z-30 bg-white shadow-lg max-h-[380px] md:max-h-[490px] -mt-16 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none w-[230px] -ml-24 md:ml-0"
                        >
                            <ListboxOption as="template" v-for="size in sizesB" :key="size" :value="size" v-slot="{ active, selected }">
                                <li
                                    :class="[selected || active ? 'bg-gray-100' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-4 border-t border-gray-100']"
                                    @click="$emit('update:valueB', size)"
                                >
                                    <div class="flex items-center space-x-2">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']"> {{ size }}<span class="text-gray-600 text-sm ml-1">mm</span> </span>
                                        <span v-if="size == 10 || size == 9" class="text-gray-600 block text-xs">Halve cilinder</span>
                                        <span v-else-if="(product && product.brand_id == 1) || mks" class="text-gray-400 block text-xs">(past ook op {{ size - 2 }} mm)</span>
                                    </div>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
    props: {
        options: Object,
        product: Object,
        valueA: Number,
        valueB: Number,
        index: Number,
        invalid: Boolean,
        focusIndex: Number,
        wizard: Boolean,
        categoryFilter: Boolean,
        mks: Boolean,
        disabled: Boolean,
        availableSizesB: Object,
    },

    data() {
        return {
            sizeBisLoading: false,
            sizesB: [],
            sizesA: this.selectedB == 10 && this.wizard ? this.options.slice(1) : this.options,
        };
    },

    methods: {
        focus(value) {
            if (value) {
                setTimeout(() => {
                    return true;
                }, 1000);
            } else {
                setTimeout(() => {
                    return false;
                }, 1000);
            }
        },
        updateOptionsSizeB(size) {
            if (this.wizard || this.categoryFilter || this.mks) {
                this.sizesB = size == 10 ? this.options.slice(1) : this.options;
                return false;
            }

            this.sizesB = this.availableSizesB[size];

            if ((this.selectedB == 10 && size == 10) || (!this.availableSizesB[size].includes(this.selectedB) && this.selectedB)) {
                this.$emit('update:valueB', this.sizesB[0]);
                this.selectedB = this.sizesB[0];
            }
        },
    },

    created() {
        if (this.selectedA) {
            this.updateOptionsSizeB(this.selectedA);
        }
    },

    setup(props) {
        const selectedA = ref(props.valueA);
        const selectedB = ref(props.valueB);

        return {
            selectedA,
            selectedB,
        };
    },
};
</script>
