import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import mitt from 'mitt';
import { createApp, h } from 'vue';
import '../css/app.css';
import './custom';

window.mitt = window.mitt || new mitt();

import Counter from '@/Blocks/Counter.vue';
import CookieConsentVue from '@/Plugins/CookieConsentVue.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Model } from 'vue-api-query';
import VueScrollTo from 'vue-scrollto';

// Tippy
import { i18nVue } from 'laravel-vue-i18n';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import VueTippy, { setDefaultProps } from 'vue-tippy';

// import { createI18n } from 'vue-i18n';
// const i18n = createI18n({
//     // something vue-i18n options here ...
//     fallbackLocale: 'nl',
//     messages: {
//         es: await import(`../lang/${locale}.json`),
//         nl: await import(`../lang/nl.json`),
//     },
// });

// import * as Sentry from '@sentry/browser';
// import * as Sentry from '@sentry/vue';
import * as Sentry from '@sentry/vue';

// document.addEventListener('inertia:finish', (event) => {
//     // Track GoSquared
//     // if(typeof _gs !== 'undefined'){
//     //     _gs('track',event.detail.visit.url.pathname)
//     // }

//     // Fallback js error
//     var url = new URL(window.location.href);

//     if (url.pathname != event.detail.visit.url.pathname && event.detail.visit.method == 'get') {
//         window.location.href = event.detail.visit.url.href;
//     }
// });

setDefaultProps({
    animation: 'shift-away',
});

// inject global axios instance as http client to Model
Model.$http = axios;

import Date from '@/Blocks/Date.vue';
import UploadFile from '@/Components/Configuration/UploadFile.vue';
import InfoModal from '@/Components/InfoModal.vue';
import ModalComponent from '@/Components/Modal.vue';
import VuePicture from '@/Components/VuePicture.vue';
import Layout from '@/Layouts/AppLayout.vue';
import Card from '@/Layouts/Card.vue';

import WizardIntro from '@/Blocks/Aside/Wizard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import Buttons from '@/Components/Configuration/Buttons.vue';
import CylinderSize from '@/Components/Configuration/CylinderSize.vue';
import KeyQty from '@/Components/Configuration/KeyQty.vue';
import ReviewStars from '@/Components/ReviewStars.vue';
import Validation from '@/Components/Validation.vue';

// Axios
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Fonts
import './fonts';
// HeadlessUI
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

createInertiaApp({
    progress: {
        color: '#369fff',
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        if (env == 'production') {
            Sentry.init({
                app,
                dsn: 'https://a0287067152c46889e7eb262898f8748@o1143293.ingest.us.sentry.io/4508060279832576',
                integrations: [Sentry.replayIntegration],
                beforeSend(event) {
                    if (event.exception && event.exception.values && event.exception.values[0]) {
                        if (event.exception.values[0].value.includes('Importing a module script failed.')) {
                            return null; // Ignore this error
                        }
                        if (event.exception.values[0].value.includes('Network Error')) {
                            return null; // Ignore this error
                        }
                        if (event.exception.values[0].value.includes('Object.prototype.hasOwnProperty.call')) {
                            return null; // Ignore this error
                        }
                    }
                    return event; // Send all other errors
                },
                // Tracing
                // tracesSampleRate: 0.1, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // tracePropagationTargets: ['localhost'],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

                // Profiling
                // profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
            });
        }

        app.use(plugin)
            .use(i18nVue, {
                // resolve: async (lang) => {
                //     const langs = import.meta.glob('../lang/*.json');
                //     return await langs[`../lang/${lang}.json`];
                // },
                resolve: async (lang) => {
                    // console.log(lang);
                    // const lang = 'nl';
                    // const langs = import.meta.glob('../../lang/*.json');
                    return await import(`../lang/${lang}.json`);
                },
            })
            // .use(i18n)
            .use(CookieConsentVue)
            .use(VueTippy)
            .use(VueScrollTo, {
                container: 'body',
                easing: 'ease-in',
                lazy: true,
                force: true,
                cancelable: true,
                onStart: function () {
                    // scrolling started
                },
                onDone: function () {
                    // scrolling is done
                },
                onCancel: function () {
                    // scrolling has been interrupted
                },
                x: false,
                y: true,
            })
            .component('InertiaLink', Link)
            .component('InertiaHead', Head)
            .component('Fa', FontAwesomeIcon)
            .component('Card', Card)
            .component('Date', Date)
            .component('Layout', Layout)
            .component('VuePicture', VuePicture)
            .component('InfoModal', InfoModal)
            .component('Modal', ModalComponent)
            .component('ReviewStars', ReviewStars)
            .component('Counter', Counter)

            // Headless UI
            .component('Menu', Menu)
            .component('MenuButton', MenuButton)
            .component('MenuItem', MenuItem)
            .component('MenuItems', MenuItems)
            .component('Popover', Popover)
            .component('PopoverButton', PopoverButton)
            .component('PopoverPanel', PopoverPanel)

            .component('RadioGroup', RadioGroup)
            .component('RadioGroupDescription', RadioGroupDescription)
            .component('RadioGroupLabel', RadioGroupLabel)
            .component('RadioGroupOption', RadioGroupOption)

            .component('Listbox', Listbox)
            .component('ListboxLabel', ListboxLabel)
            .component('ListboxButton', ListboxButton)
            .component('ListboxOption', ListboxOption)
            .component('ListboxOptions', ListboxOptions)

            .component('Dialog', Dialog)
            .component('DialogOverlay', DialogOverlay)
            .component('DialogTitle', DialogTitle)
            .component('TransitionChild', TransitionChild)
            .component('TransitionRoot', TransitionRoot)

            // Wizard
            .component('CylinderSize', CylinderSize)
            // .component('CylinderQty', CylinderQty)
            .component('Buttons', Buttons)
            .component('KeyQty', KeyQty)
            .component('Validation', Validation)
            .component('Checkbox', Checkbox)
            .component('WizardIntro', WizardIntro)

            // Need to override
            // .component('Nl2br', Nl2br)

            .mixin({
                methods: {
                    url(value, param) {
                        if (param) {
                            return `${base_url}/${value}/${param}`;
                        } else {
                            return `${base_url}/${value}`;
                        }
                    },
                    asset(value, param) {
                        if (param) {
                            return `${file_asset_url}/${value}/${param}`;
                        } else {
                            return `${file_asset_url}/${value}`;
                        }
                    },

                    price(value) {
                        if (value == 0) return '0,00';
                        if (!value) return '';
                        return currencyFormat(Number(value));
                    },

                    priceEuro(value) {
                        if (value == 0) return '0,00';
                        if (!value) return '';
                        return '€ ' + currencyFormat(Number(value));
                    },
                },

                // Get locale and return as computed property
                computed: {
                    locale() {
                        return locale;
                    },
                },
            })

            // Product
            .component('UploadFile', UploadFile)
            .mount(el);

        return app;
    },
});

function currencyFormat(num) {
    return num
        .toFixed(2)
        .replace('.', ',')
        .replace(',00', ',-')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
