<template>
    <nav
        class="bg-white text-gray-500 top-nav h-[61px] md:h-[69px] z-40 border-b border-gray-100"
        :class="fixedHeader ? 'fixed md:sticky right-0 left-0 top-0' : 'relative'"
    >
        <div class="border-t-4 border-dgreen w-full"></div>
        <div class="max-w-screen-2xl mx-auto px-2 lg:px-8">
            <div class="relative flex justify-between h-[54px] md:h-[64px] z-30 items-center">
                <mobile-menu />

                <div class="flex pl-12 md:pl-0 items-center justify-center md:items-stretch md:justify-start">
                    <div class="flex-shrink-0 flex items-center">
                        <inertia-link href="/">
                            <logo-white as="div" class="block w-auto" v-if="!showMobile" />
                        </inertia-link>
                    </div>
                </div>

                <div class="md:flex-1 items-center md:px-2 lg:ml-6 lg:justify-left md:w-64 grow">
                    <div class="md:flex items-center lg:justify-left relative z-20" v-if="showSearch == false">
                        <fa
                            :icon="['fal', 'search']"
                            class="text-gray-400 fa-search absolute right-2 md:left-0 -top-5 md:top-0.5 my-3 ml-4 z-30 w-5 h-5 md:w-4"
                            @click="showMobileSearch()"
                        />

                        <input
                            @click="showSearch = true"
                            type="search"
                            readonly="true"
                            :placeholder="$t('zoeken')"
                            class="z-20 w-48 hidden md:block md:w-full text-gray-700 pl-10 pr-3 py-3 border border-gray-400 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-gray-500 md:text-sm"
                        />
                    </div>
                    <searchbar
                        ref="searchbarref"
                        :key="refreshKey"
                        v-if="showSearch == true"
                    />
                </div>

                <nav-usps class="hidden md:flex" />

                <div class="flex justify-end items-center pr-2 md:static md:inset-auto md:ml-2 md:pr-0">
                    <!-- <button
                        class="px-2 py-2 rounded-md flex text-sm focus:outline-none md:hidden"
                        title="Zoeken"
                        @click="!showMobile ? showMobileSearch() : hideMobileSearch()"
                    >
                        <fa v-if="showMobile" :icon="['fal', 'times']" class="opacity-80 text-xl" />
                        <fa v-else :icon="['fal', 'search']" class="opacity-80 text-xl" />
                    </button> -->

                    <account-menu v-if="!showMobile" />

                    <shopping-cart />
                    <!-- <shopping-cart-link v-if="!showMobile" /> -->
                </div>
            </div>
        </div>
    </nav>
    <main-menu
        :class="fixedHeader ? 'mb-[56px] md:mb-0 top-[61px] md:top-[69px] relative md:sticky right-0 left-0 z-10 md:z-30' : 'relative'"
        v-if="!$page.props.active.checkout"
    />

    <div
        class="max-w-screen-2xl mx-auto px-2 lg:px-8 my-4"
        v-if="$page.props.settings && $page.props.settings.header_message"
    >
        <div
            class="rounded-md px-4 py-2 w-full border"
            :class="
                'bg-' +
                $page.props.settings.header_message_color +
                '-100 bg-opacity-50 border-' +
                $page.props.settings.header_message_color +
                '-500'
            "
        >
            <fa :icon="['fal', $page.props.settings.header_message_icon]" class="mr-2" />
            {{ $page.props.settings.header_message_text }}
        </div>
    </div>

    <div class="max-w-screen-2xl flex container mx-auto px-2 lg:px-8 my-4" v-if="impersonated">
        <div class="w-full">
            <div
              
                class="bg-red-700 text-white rounded-md px-5 py-3 w-full hover:text-white relative transition-all border flex justify-between items-center"
            >
                <fa :icon="['fas', 'user']" class="h-5 w-5 mr-3 text-white" />
                <div class="inline mr-1"><strong>{{ impersonated.name }}. </strong>Ingelogd als: <strong>{{ usePage().props.user.name }} ({{ usePage().props.user.email }})</strong></div>
                <a :href="url('impersonate/leave')">
                    <fa :icon="['fas', 'sign-out']" class="h-5 w-5  text-white" />
                </a>
            </div>
        </div>
    </div>

    <div
        class="max-w-screen-2xl flex container mx-auto px-2 lg:px-8 my-4"
        v-if="
            $page.props.settings &&
            $page.props.settings.sale_enabled &&
            route != 'black-friday/' &&
            route != 'checkout/'
        "
    >
     <!-- <div class="bg-black text-white rounded shadow-md flex mx-4 px-2 lg:px-8 my-1 w-full" v-if="$page.props.settings && $page.props.settings.sale_enabled && route != 'black-friday/'">
        <div class="mx-auto max-w-screen-2xl container">
            <inertia-link
                :href="$page.props.settings.sale_url"
                class=" px-5 py-3 w-full block hover:text-white "
            >
                <fa :icon="['fas', 'fa-star']" class="h-5 w-5 mr-3 text-red-500" />
                <div class="inline mr-2" v-html="$page.props.settings.sale_header"></div>
                <fa :icon="['fas', 'fa-star']" class="h-5 w-5 text-red-500 float-right mt-1 hidden md:block" />

                <Counter class="block md:inline-block" />
            </inertia-link>å
        </div>
    </div> -->
        <div class="w-full">
            <inertia-link
                :href="$page.props.settings.sale_url"
                class="bg-black text-white rounded-md px-5 py-3 w-full block hover:text-white shadow-red-700/20 relative shadow-overallxs hover:shadow-red-700/30 transition-all border border-red-700"
            >
                <fa :icon="['fas', 'fa-star']" class="h-5 w-5 mr-3 text-red-500" />
                <div class="inline mr-1" v-html="$page.props.settings.sale_header"></div>
                <fa :icon="['fas', 'fa-star']" class="h-5 w-5 text-red-500 float-right mt-1 hidden md:block" />
                <span>- </span>
                <Counter class="block md:inline-block" />
            </inertia-link>
        </div>
    </div>
</template>

<script setup>
import LogoWhite from '@/Blocks/Logo.vue';
import NavUsps from '@/Blocks/NavUsps.vue';
import { usePage } from '@inertiajs/vue3';
import { computed, defineAsyncComponent, ref } from 'vue';
import AccountMenu from './AccountMenu.vue';
import MainMenu from './Menu.vue';
import MobileMenu from './MobileMenu.vue';
import ShoppingCart from './ShoppingCart.vue';

const Searchbar = defineAsyncComponent(() => import('@/Components/Searchbar.vue'));

const showMobile = ref(false);
const refreshKey = ref(0);
const searchbarref = ref(0);
const showSearch = ref(false);

const page = usePage();
const route = computed(() => page.props.route);

const impersonated = computed(() => {
    return page.props.impersonated_by;
});

const fixedHeader = computed(() => {
    let active = page.props.active;
    return !active.product && !active.cart && !active.checkout && !active.order_return && !showSearch.value;
});

function showMobileSearch() {
    showSearch.value = true;
    showMobile.value = true;
    if(searchbarref.value){
        searchbarref.value.showMobile = true;
    } else {
        setTimeout(() => {
            if(searchbarref.value){
                searchbarref.value.showMobile = true;
            } 
        }, 100);
    }
}

</script>
