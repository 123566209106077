<template>
    <slot name="button">
        <button
            v-if="type == 'button'"
            class="inline-flex items-center justify-center p-2 rounded-md  focus:outline-none relative"
            @click="open = true"
        >
            <slot name="badge"></slot>

            <span class="sr-only">{{ title }}</span>
            <fa :icon="['fal', icon]" class="text-xl" />
        </button>
        <button v-if="type == 'link'" class="inline-flex items-center lg:hidden" @click="open = true">
            <span class="sr-only">{{ title }}</span>
            <span class="text-sm font-medium text-gray-700">{{ title }}</span>
            <fa v-if="icon" :icon="['fal', icon]" class="lex-shrink-0 ml-1 h-5 w-5 text-gray-600" />
        </button>
    </slot>


    <TransitionRoot as="template" :show="open">
        <Dialog as="div" static class="z-30 fixed inset-0 overflow-hidden" style="z-index: 2147483647;" @close="open = false" :open="open">
            <div
                class="absolute top-0 h-full w-10 z-20"
                :class="{ 'right-0': pin == 'left', 'left-0': pin == 'right' }"
                @click="open = false"
            ></div>
            <div class="absolute inset-0 overflow-hidden">
                <TransitionChild
                    as="template"
                    enter="ease-in-out duration-200"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-200"
                    leave-from="opacity-10"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="absolute inset-0 bg-black bg-opacity-20 transition-opacity" />
                </TransitionChild>

                <div
                    :class="pin == 'right' ? 'right-0 pl-10 ' : 'left-0 pr-10'"
                    class="fixed inset-y-0 max-w-full flex"
                >
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-200"
                        :enter-from="pin == 'right' ? 'translate-x-full' : '-translate-x-full'"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-200"
                        leave-from="translate-x-0"
                        :leave-to="pin == 'right' ? 'translate-x-full' : '-translate-x-full'"
                    >
                        <div class="w-screen max-w-md">
                            <div class="h-full flex flex-col py-6 bg-white shadow-xl">
                                <div class="px-6">
                                    <div class="flex items-start justify-between ">
                                        <DialogTitle class="text-lg font-bold text-gray-900 ">
                                            {{ title }}
                                        </DialogTitle>
                                        <div class="ml-3 h-7 flex items-center">
                                            <button
                                                class="px-4 py-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                                                @click="open = false"
                                            >
                                                <span class="sr-only">Sluiten</span>
                                                <fa :icon="['fal', 'times']" class="block text-2xl" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="h-full mt-6 relative flex-1 pb-20 overflow-y-auto" :class="padding ? 'px-6' : ''">
                                    <!-- Replace with your content -->
                                    <div class="" id="slide-content">
                                        <slot></slot>
                                    </div>
                                    
                                    <!-- /End replace -->
                                    <slot name="bottom">
                                        
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
const inBrowser = typeof window !== 'undefined';

export default {
    props: {
        title: String,
        padding: Boolean,
        pin: String,
        icon: String,
        type: String,
    },

    created() {
        if (inBrowser) {
            window.mitt.on('close-slideover', (evt) => {
                this.open = false;
            });
        }
    },

    setup(props) {
        const open = ref(false);
        const contents = ref(null);

        return {
            open,
            contents,
        };
    },
};
</script>
