<template>
    <nav class="bg-white shadow-menu relative" :class="fixedHeader ? ' mb-[0px] md:mb-0' : ''">
        <div class="max-w-screen-2xl mx-auto lg:px-8">
            <div class="relative flex justify-between h-10 md:h-16 items-center">
                <div class="-mx-2">
                    <!-- Main menu -->
                    <div class="hidden md:ml-1 md:flex md:space-x-2">
                        <flyout-menu title="Cilindersloten">
                            <div class="grid grid-cols-12 gap-y-10 gap-x-8 py-8 px-4 md:px-6 xl:pr-12">
                                <div class="grid grid-cols-3 col-span-7 gap-y-10 gap-x-8">
                                    <div class="">
                                        <!-- Sets 1 to 7 -->
                                        <inertia-link
                                            title="Gelijksluitende sets"
                                            :href="url('cilindersloten')"
                                            class="pl-3 inline-block text-gray-800 hover:text-gray-600 font-semibold mb-3"
                                            >Gelijksluitende sets</inertia-link
                                        >
                                        <inertia-link :href="url('cilindersloten')" class="main-nav-link" title="Cilindersloten"> 1 cilinder </inertia-link>

                                        <inertia-link v-for="n in [2, 3, 4, 5, 6, 7]" :href="url('cilindersloten/sets/' + n + '-stuks')" class="main-nav-link" :key="n">
                                            {{ n }} cilinders
                                        </inertia-link>
                                    </div>

                                    <!-- Button / Half / Half-button -->
                                    <div class="">
                                        <span class="pl-3 inline-block text-gray-800 font-semibold mb-3">Type cilinders </span>

                                        <inertia-link :href="url('cilindersloten')" title="Cilindersloten" class="main-nav-link"> Standaard cilinders </inertia-link>

                                        <inertia-link :href="url('cilindersloten/met-knop')" title="Cilindersloten met knop" class="main-nav-link"> Knopcilinder </inertia-link>

                                        <inertia-link :href="url('cilindersloten/halve-cilinder')" title="Halve cilinders" class="main-nav-link"> Halve cilinder </inertia-link>

                                        <inertia-link :href="url('cilindersloten/halve-cilinder-met-knop')" title="Halve cilinders met knop" class="main-nav-link">
                                            Halve knopcilinder
                                        </inertia-link>

                                        <inertia-link :href="url('cilindersloten/certificaat')" title="Certificaat" class="main-nav-link"> Cilinders met certificaat </inertia-link>

                                        <inertia-link :href="url('cilindersloten/gelijksluitend')" title="Gelijksluitend" class="main-nav-link">
                                            Gelijksluitende cilinders
                                        </inertia-link>

                                        <inertia-link :href="url('cilindersloten/skg3')" title="skg3" class="main-nav-link"> Met SKG3 keurmerk </inertia-link>
                                    </div>

                                    <!-- Merken -->
                                    <div class="">
                                        <inertia-link title="Merken cilinders" :href="url('cilindersloten/merken')" class="pl-3 inline-block text-gray-800 font-semibold mb-3"
                                            >Merken</inertia-link
                                        >
                                        <inertia-link :href="url('cilindersloten/merken/' + brand.slug)" class="main-nav-link" :title="brand.name" v-for="brand in brands">
                                            {{ brand.name }}
                                        </inertia-link>

                                        <span class="pl-3 inline-block text-gray-800 font-semibold mb-3 mt-6">Handige links</span>
                                        <inertia-link :href="url('advies/cilinderslot-opmeten')" class="main-nav-link" title=""> Opmeten van een cilinderslot </inertia-link>
                                        <inertia-link :href="url('accessoires')" title="Cilinderslot accessoires" class="main-nav-link"> Accessoires </inertia-link>
                                    </div>
                                </div>

                                <div class="col-span-5">
                                    <WizardIntro />
                                </div>
                            </div>
                            <inertia-link :href="url('cilindersloten')" class="px-8 py-4 bg-gray-50 w-full block hover:bg-gray-100 font-bold">
                                Bekijk alle cilinders <fa :icon="['fal', 'angle-right']" class="ml-3 w-4 h-4" />
                            </inertia-link>
                        </flyout-menu>

                        <div v-for="item in menu" :key="item.id">
                            <dropmenu :title="item.name" v-if="item.children.length && item.name != 'Cilindersloten' && item.slug != 'smartlocks'">
                                <div v-for="child in item.children">
                                    <MenuItem :key="child.id" v-slot="{ active }">
                                        <inertia-link
                                            :href="item.slug == 'nabestellen' ? url(item.slug + '/' + child.slug) : url(child.slug)"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-6 py-3 text-gray-700',
                                                child.children.length ||
                                                ['Oplegsloten nabestellen', 'Hangsloten nabestellen', 'Staartcilinder nabestellen', 'Staartcilinders', 'Sloten'].includes(
                                                    child.name
                                                )
                                                    ? 'font-bold'
                                                    : '',
                                            ]"
                                            :title="child.name"
                                            >{{ child.name }}</inertia-link
                                        >
                                    </MenuItem>

                                    <div v-if="child.children.length" v-for="subchild in child.children">
                                        <inertia-link
                                            v-if="item.slug == 'nabestellen'"
                                            :href="url(item.slug + '/' + child.slug + '/' + subchild.slug)"
                                            class="hover:bg-gray-100 block px-6 py-2 pl-8 text-gray-700"
                                            :title="subchild.name"
                                            >{{ subchild.name }}</inertia-link
                                        >
                                        <inertia-link
                                            v-else
                                            :href="url(child.slug + '/' + subchild.slug)"
                                            class="hover:bg-gray-100 block px-6 py-3 pl-8 text-gray-700"
                                            :title="subchild.name"
                                            >{{ subchild.name }}</inertia-link
                                        >
                                    </div>
                                </div>
                                <inertia-link
                                    v-if="item.name == 'Deurbeslag'"
                                    :href="url('deurbeslag')"
                                    title="Bekijk al het deurbeslag"
                                    class="px-8 py-4 bg-gray-50 w-full block hover:bg-gray-100 font-bold"
                                >
                                    Bekijk al het deurbeslag <fa :icon="['fal', 'angle-right']" class="ml-3 w-4 h-4" />
                                </inertia-link>
                            </dropmenu>

                            <flyout-menu title="Smart locks" v-if="item.slug == 'smartlocks'">
                                <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-8 px-4 md:px-6 xl:pr-12">
                                    <div class="grid grid-cols-2 gap-y-10 gap-x-8">
                                        <div class="">
                                            <!-- Sets 1 to 7 -->
                                            <inertia-link
                                                title="Cilinders met smart lock"
                                                :href="url('smartlocks/cilinder-met-smart-lock')"
                                                class="pl-3 inline-block text-gray-800 hover:text-gray-600 font-semibold mb-3"
                                                >Cilinders met smart lock</inertia-link
                                            >
                                            <inertia-link :href="url('smartlocks/cilinder-met-smart-lock')" class="main-nav-link" title="Cilinders met smart lock">
                                                1 cilinder
                                            </inertia-link>

                                            <inertia-link
                                                v-for="n in [2, 3, 4, 5, 6, 7]"
                                                :href="'/smartlocks/cilinder-met-smart-lock/' + n + '-cilinders'"
                                                class="main-nav-link"
                                                :title="n + ' cilinders met smart lock'"
                                            >
                                                {{ n }} cilinders
                                            </inertia-link>
                                        </div>

                                        <div class="">
                                            <span class="pl-3 inline-block text-gray-800 font-semibold mb-1">Smart lock merken </span>

                                            <inertia-link :href="url('smartlocks/nuki')" title="Nuki smart locks" class="main-nav-link"> Nuki </inertia-link>

                                            <inertia-link :href="url('smartlocks/tedee')" title="Tedee smart locks" class="main-nav-link"> Tedee </inertia-link>

                                            <strong class="block pl-3 text-gray-800 font-semibold mb-1 mt-6">Handige links </strong>

                                            <inertia-link :href="url('smartlocks')" title="Informatie over smart locks" class="main-nav-link"> Smart lock informatie </inertia-link>

                                            <!-- <inertia-link
                                                :href="url('smartlocks-top-5')"
                                                title="Smart locks top 5"
                                                class="main-nav-link"
                                            >
                                                Top 5 smart locks
                                            </inertia-link> -->

                                            <inertia-link :href="url('smartlocks/smart-lock-accessoires')" title="Tedee smart locks" class="main-nav-link">
                                                Accessoires
                                            </inertia-link>
                                        </div>
                                    </div>

                                    <div class="w-3/4">
                                        <inertia-link :href="url('smartlocks/alle-smart-locks')" title="Bekijk alle smart locks">
                                            <img :src="asset('img/smart-locks-vergelijken.png')" class="rounded-xl object-cover" />
                                        </inertia-link>
                                    </div>
                                </div>
                                <inertia-link
                                    :href="url('smartlocks/alle-smart-locks')"
                                    title="Bekijk alle smart locks"
                                    class="px-8 py-4 bg-gray-50 w-full block hover:bg-gray-100 font-bold"
                                >
                                    Bekijk alle smart locks <fa :icon="['fal', 'angle-right']" class="ml-3 w-4 h-4" />
                                </inertia-link>
                            </flyout-menu>

                            <inertia-link
                                v-if="!item.children.length && item.slug != 'smartlocks'"
                                :href="url(item.slug)"
                                class="main-nav-link"
                                :class="item.name && $page.props.active.category == item.name.toLowerCase() ? 'bg-gray-50' : ''"
                            >
                                {{ item.name }}
                            </inertia-link>
                        </div>

                        <inertia-link :href="url('sluitplan')" class="main-nav-link" title="Sluitplan opstellen"> Sluitplan </inertia-link>
                        <inertia-link :href="url('advies')" class="main-nav-link" title="Lees hier het advies van onze experts"> Advies </inertia-link>
                        <dropmenu title="Custom" v-if="user && (user.is_admin || impersonated)">
                            <inertia-link :href="url('product/custom')" class="main-nav-link" title="Custom product"> Custom </inertia-link>
                            <inertia-link :href="url('gamma')" class="main-nav-link" title="Custom product"> Gamma </inertia-link>
                            <inertia-link :href="url('karwei')" class="main-nav-link" title="Custom product"> karwei </inertia-link>
                        </dropmenu>
                    </div>
                </div>

                <!-- USPS mobile -->
                <div class="flex md:hidden text justify-center w-full text-gray-700">
                    <inertia-link class="hover:text-gray-600" :href="url('klantenservice', 'levertijd-verzendkosten')" title="Klantenservice Doorax">
                        <fa :icon="['fal', 'check']" class="text-green-600 mr-2 w-4 inline-block" />
                        Voor
                        <strong>23:59</strong> besteld, morgen <strong class="lg:hidden">gratis</strong> in huis!
                    </inertia-link>
                </div>

                <div class="hidden md:flex justify-end items-center">
                    <!-- Klantenservice -->
                    <inertia-link
                        :href="url('klantenservice')"
                        class="hidden xl:inline-flex border-transparent text-gray-500 transition-colors duration-100 rounded-lg hover:bg-gray-50 hover:text-gray-700 items-center px-3 py-2 font-medium mr-4"
                    >
                        <fa :icon="['fad', 'circle-dot']" v-if="$page.props.online" class="text-xs h-3 mr-2 text-green-500" />
                        <span>Klantenservice</span>
                    </inertia-link>

                    <!-- Kiyoh -->
                    <kiyoh />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Kiyoh from '@/Blocks/Kiyoh.vue';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import Dropmenu from '../Components/Dropmenu.vue';
import FlyoutMenu from '../Components/FlyoutMenu.vue';

export default {
    data() {
        return {
            brands: [
                { name: 'M&C', url: 'mc', slug: 'mc' },
                { name: 'Nemef', url: 'nemef', slug: 'nemef' },
                { name: 'Abus', url: 'abus', slug: 'abus' },
                // { name: 'EVVA', url: 'evva', slug: 'evva' },
            ],
        };
    },

    components: {
        FlyoutMenu,
        Dropmenu,
        Kiyoh,
    },

    computed: {
        fixedHeader() {
            let active = this.$page.props.active;
            return !active.product && !active.cart && !active.checkout && !active.order_return;
        },
    },

    setup() {
        const menu = computed(() => usePage().props.menu);
        const user = computed(() => usePage().props.user);
        const impersonated = computed(() => usePage().props.impersonated_by);

        return { menu, user, impersonated };
    },
};
</script>
