<template>
    <picture v-if="image">
        <source :srcset="image.webp_src || image.src" type="image/webp" />
        
        <img
            v-bind="commonImageProps"
            :width="width"
            :height="height"
            v-if="width || height"
        />
        <img
            v-bind="commonImageProps"
            v-else
        />
    </picture>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
    image: {
        type: Object,
        default: null,
    },
    name: {
        type: String,
        default: '',
    },
    style: {
        type: String,
        default: '',
    },
    notlazy: {
        type: Boolean,
        default: false,
    },
    width: {
        type: Number,
    },
    height: {
        type: Number,
    },
    fph: {
        type: Boolean,
        default: false,
    },
});

// Compute common image properties
const commonImageProps = computed(() => ({
    srcset: props.image.png_src ?? props.image.origin_src,
    src: props.image.origin_src,
    alt: props.name,
    class: ['max-h-full', props.style ?? ''],
    decoding: props.fph ? 'auto' : 'async',
    fetchpriority: props.fph ? 'high' : props.notlazy ? 'auto' : 'low',
    loading: props.notlazy ? 'eager' : 'lazy'
}));
</script>
