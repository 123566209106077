<template>
    <div class="absolute inset-y-0 flex items-center md:hidden">
        <slide-over pin="left" title="Menu" icon="bars" type="button">
            <div class="pb-4 text-gray-800 px-6">
                <Disclosure>
                    <!-- <DisclosureButton></DisclosureButton> -->

                    <DisclosureButton class="text-left w-full py-2 flex justify-between">
                        <span class="font-medium"> Cilindersloten </span>
                        <span class="mr-2.5 h-7 flex items-center">
                            <fa
                                :icon="['fal', 'angle-down']"
                                :class="[open ? 'fa-rotate-180' : '', 'h-6 w-6 transform']"
                            />
                        </span>
                    </DisclosureButton>

                    <!-- Use the built-in <transition> component to add transitions. -->
                    <transition name="pop">
                        <DisclosurePanel class="border-b pb-10 mb-10">
                            <!-- sets -->
                            <inertia-link
                                title="Gelijksluitende sets"
                                href="/cilindersloten"
                                class="inline-block hover:text-gray-600 font-semibold mb-1"
                                >Gelijksluitende sets</inertia-link
                            >
                            <inertia-link href="/cilindersloten" class="block w-full py-2">
                                <fa :icon="['fal', 'angle-right']" class="mr-2" />1 cilinder
                            </inertia-link>

                            <inertia-link
                                v-for="n in [2, 3, 4, 5, 6, 7]"
                                :href="'/cilindersloten/sets/' + n + '-stuks'"
                                class="block w-full py-1"
                            >
                                <fa :icon="['fal', 'angle-right']" class="mr-2" />{{ n }} cilinders
                            </inertia-link>

                            <inertia-link
                                href="/cilindersloten"
                                class="py-1 inline-block text-blue-600"
                                ><fa :icon="['fal', 'angle-right']" class="mr-2" />Bekijk alle cilinders</inertia-link
                            >

                            <!-- Type cilinders -->
                            <div class="mt-6">
                                <span class="inline-block font-semibold mb-1">Type cilinders </span>

                                <inertia-link href="/cilindersloten" class="block w-full py-1">
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />Standaard cilinders
                                </inertia-link>

                                <inertia-link href="/cilindersloten/met-knop" class="block w-full py-1">
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />Knopcilinder
                                </inertia-link>

                                <inertia-link href="/cilindersloten/halve-cilinder" class="block w-full py-1">
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />Halve cilinder
                                </inertia-link>

                                <inertia-link
                                    href="/cilindersloten/halve-cilinder-met-knop"
                                    class="block w-full py-1"
                                >
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />Halve knopcilinder
                                </inertia-link>

                                <inertia-link
                                    href="/cilindersloten/halve-cilinder-met-knop"
                                    class="block w-full py-1"
                                >
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />Cilinders met smartlock
                                </inertia-link>
                            </div>

                            <!-- Merken -->
                            <div class="mt-6">
                                <inertia-link
                                    title="Merken cilinders"
                                    href="/cilindersloten/merken"
                                    class="inline-block font-semibold mb-1"
                                    >Merken</inertia-link
                                >
                                <inertia-link
                                    :href="'/cilindersloten/merken/' + brand.url"
                                    class="block w-full py-1"
                                    v-for="brand in brands"
                                >
                                    <fa :icon="['fal', 'angle-right']" class="mr-2" />{{ brand.name }}
                                </inertia-link>
                            </div>
                        </DisclosurePanel>
                    </transition>
                </Disclosure>

                <div v-for="item in menu" :key="item.id">
                    <Disclosure>
                        <DisclosureButton
                            class="text-left w-full py-2 flex justify-between"
                            v-if="item.children.length && item.name != 'Cilindersloten'"
                        >
                            <span class="font-medium">{{ item.name }}</span>
                            <span class="mr-2.5 h-7 flex items-center">
                                <fa
                                    :icon="['fal', 'angle-down']"
                                    :class="[open ? 'fa-rotate-180' : '', 'h-6 w-6 transform']"
                                />
                            </span>
                        </DisclosureButton>

                        <!-- Use the built-in <transition> component to add transitions. -->
                        <transition name="pop">
                            <DisclosurePanel class="border-b pb-10 mb-10">
                                <div v-for="child in item.children" :key="child.id">
                                    <inertia-link
                                        :href="url(child.slug)"
                                        class="block py-2"
                                        ><fa :icon="['fal', 'angle-right']" class="mr-2" />{{
                                            child.name
                                        }}</inertia-link
                                    >
                                </div>
                            </DisclosurePanel>
                        </transition>
                    </Disclosure>

                    <inertia-link
                        v-if="!item.children.length"
                        :href="url(item.slug)"
                        class="block py-2"
                        :class="item.name && $page.props.active.category == item.name.toLowerCase() ? 'bg-gray-50' : ''"
                    >
                        {{ item.name }}
                    </inertia-link>
                </div>

                <inertia-link :href="url('sluitplan')" class="block py-2" title="Sluitplan opstellen"> Sluitplan</inertia-link>
                <inertia-link :href="url('advies')" title="Lees hier het advies van onze experts"> Advies</inertia-link>
                <inertia-link :href="url('klantenservice')" class="block py-2" title="Klantenservice Doorax"> Klantenservice</inertia-link>

                <div class="relative mt-5 pt-5 border-t">
                     <WizardIntro />
                </div>

            </div>
        </slide-over>
    </div>
</template>

<script>
import SlideOver from '../Components/SlideOver.vue';
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
const inBrowser = typeof window !== 'undefined';

export default {
    components: {
        SlideOver,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },

    data() {
        return {
            brands: [
                { name: 'M&C', url: 'mc' },
                { name: 'Nemef', url: 'nemef' },
                { name: 'Abus', url: 'abus' },
            ],
        };
    },

    setup() {
        
        const open = ref(false)
        const menu = computed(() => usePage().props.menu);

        return { menu, open };
    },
};
</script>
