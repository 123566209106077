<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Popover class="" v-slot="{ open }">
    <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'border-transparent transition-colors duration-100 hover:bg-gray-50 focus:bg-white hover:text-gray-700 inline-flex items-center px-3 py-2 font-medium focus:outline-none rounded-lg']">
        {{ title }}
        <fa :icon="['fal', 'angle-down']" v-if="!open" class="ml-2 h-5 w-5 group-hover:text-gray-500" />
        <fa :icon="['fal', 'angle-up']" v-if="open" class="ml-2 h-5 w-5 group-hover:text-gray-500" />
    </PopoverButton>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
      <PopoverPanel class="absolute z-30 inset-x-0 transform shadow-lg mt-3  overflow-hidden rounded-b-lg">
        <div class="relative w-full mx-auto bg-white">
          <slot></slot>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>

export default {
    
  props:["title"],

  setup() {
    return {

    }
  },
}
</script>