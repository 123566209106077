<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed inset-0 overflow-y-scroll" style="z-index: 9999999999" @close="open = false;close();">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-48 text-center md:block md:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-200"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-200" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-200"
                    enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                    enter-to="opacity-100 translate-y-0 md:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 md:scale-100"
                    leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-white rounded-xl w-full text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-5xl"
                        :class="withoutPadding ? '' : 'px-4 pt-5 pb-4 md:p-6'"
                    >
                        <div>
                            <div :class="withoutPadding ? '' : 'p-4'">
                                <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900" v-if="title">
                                    {{ title }}
                                </DialogTitle>
                                 <button
                                    class="absolute right-0 top-0 p-4 m-1 hover:text-gray-700 text-gray-400 ring-0 border-0"
                                    @click="
                                        open = !open;
                                        close();
                                    "
                                >
                                    <fa :icon="['fal', 'times']" class="h-5 w-5" />
                                </button>
                                <div class="mt-2">
                                    <slot name="content">
                                        <div v-if="!body" class="flex justify-center w-full py-12">
                                            <fa
                                                :icon="['fad', 'spinner-third']"
                                                class="text-black fa-2x opacity-80 mr-2 fa-spin"
                                            />
                                        </div>
                                        <div v-else class="text-gray-800" v-html="body"></div>
                                    </slot>
                                </div>

                                <button class="absolute"></button>
                               
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref } from 'vue';

export default {
    props: {
        title: String,
        body: String|Boolean,
        withoutPadding: Boolean,
    },
    setup(props,context) {
        const open = ref(false);
        function close(){
            context.emit('close')
        }
        return {
            open,close
        };
    },
};
</script>
