<template>
    <div class="flex flex-wrap relative" :class="{ 'opacity-50': !cylinder.a || !cylinder.b || options.length == 1 }">
        <div v-if="disabled" class="z-20 inset-0 bg-white bg-opacity-50 absolute transition-all"></div>
        <Listbox as="div" v-model="selected" class="w-full">
            <ListboxLabel
                class="block text-sm font-bold text-gray-700 mb-1"
                :class="{ 'block md:hidden': (index > 0 && mks) || wizard }"
            >
                <info-modal title="Knop op cilinder" class="text-sm" :infoIcon="true" :body="info">Knop</info-modal>
            </ListboxLabel>
            <div class="relative">
                <div
                    v-if="!cylinder.a || !cylinder.b || options.length == 1"
                    class="z-20 absolute inset-0 cursor-not-allowed"
                ></div>

                <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-4 pr-8 text-left cursor-pointer focus:outline-none hover:shadow-md transition-shadow"
                    :class="{ 'py-3': categoryFilter, 'py-4': !categoryFilter }"
                    :id="'closebtn' + index"
                >
                    <span v-if="selected && !disabled" class="flex items-center">
                        <img :src="selected.image.src" v-if="selected.image" class="h-6 inline-block mr-3" />
                        <span class="inline-block truncate">{{ selected.value }}</span>
                    </span>
                    <span v-else class="block truncate">...</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                        <fa :icon="['fal', 'angle-down']" class="h-5 w-5 text-gray-400" />
                    </span>
                </ListboxButton>

                <transition name="drop">
                    <ListboxOptions
                        class="absolute z-30 bg-white w-64 shadow-lg max-h-[380px] md:max-h-fit -mt-16 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
                        :class="
                            (!wizard && !categoryFilter ? 'w-[20rem]' : '',
                            smartlock_adapters && smartlock_adapters.length ? 'md:min-w-[36rem] md:-left-40' : '')
                        "
                    >
                        <div class="flex flex-wrap">
                            <div
                                :class="
                                    smartlock_adapters && smartlock_adapters.length
                                        ? 'w-full md:w-1/2 md:border-r'
                                        : 'w-full'
                                "
                            >
                                <div
                                    class="px-4 py-2 font-bold"
                                    v-if="smartlock_adapters && smartlock_adapters.length && cylinder.b != 10"
                                >
                                    <label class="text-sm">Knoppen</label>
                                </div>
                                <ListboxOption
                                    as="template"
                                    v-for="button in filteredOptions"
                                    :key="button"
                                    :value="button"
                                    v-slot="{ active, selected }"
                                >
                                    <li
                                        :class="[
                                            selected || active ? 'bg-gray-100' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-4 pr-2',
                                        ]"
                                        @click="
                                            emit('update:button', button);
                                            cylinder.smartlock = false;
                                        "
                                        :hidden="cylinder.b == 10 || !button.is_active"
                                    >
                                        <div
                                            :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']"
                                            class="flex items-center"
                                        >
                                            <img
                                                :src="button.image.src"
                                                v-if="button.image"
                                                class="h-12 inline-block mr-4"
                                            />
                                            <div class="h-12" v-else-if="!wizard"></div>
                                            <span>
                                                <span class="text-gray-700 ml-1 block">{{ button.value }}</span>
                                                <span
                                                    class="text-gray-500 ml-1 block text-xs"
                                                    v-if="button.value != 'Geen knop' && button.value != 'Geen'"
                                                    >op {{ cylinder.b }}mm</span
                                                >
                                            </span>

                                            <span class="grow text-right">
                                                <span
                                                    v-if="button.price > 0"
                                                    class="rounded px-2 py-1 text-xs bg-gray-200 ml-2"
                                                    >+ {{ price(button.price) }}</span
                                                >
                                            </span>
                                        </div>
                                    </li>
                                </ListboxOption>
                            </div>
                            <div
                                class="w-full md:w-1/2"
                                v-if="smartlock_adapters && smartlock_adapters.length && cylinder.b != 10"
                            >
                                <div class="px-4 py-2 font-bold">
                                    <label class="text-sm">Smart locks</label>
                                </div>
                                <div class="px-4 py-2 font-bold" v-if="!cylinder.smartlock">
                                    <button class="button default w-full" @click="showModalSmartlock(index)">
                                        <fa :icon="['fal', 'plus']" class="h-4 w-4 mr-2" />
                                        Smart lock toevoegen
                                    </button>
                                </div>
                                <div class="px-4 py-2 font-bold" v-if="cylinder.smartlock">
                                    <div class="flex flex-col justify-center w-full mb-2">
                                        <vue-picture :image="button.image" :style="'mx-auto w-24'" />
                                        <span class="font-bold">{{ button.value }}</span>
                                    </div>
                                    <button class="button border w-full border-red-600" @click="removeSmartlock">
                                        <fa :icon="['fas', 'minus-circle']" class="h-4 w-4 mr-2 text-gray-600" />
                                        Smart lock verwijderen
                                    </button>
                                </div>
                                <div class="px-4 py-2 font-bold">
                                    <info-modal title="Smart lock adapters" class="text-sm" :infoIcon="true" :body="infoAdapters">Smart lock adapters</info-modal>
                                </div>
                                <ListboxOption
                                    as="template"
                                    v-for="adapter in smartlock_adapters"
                                    :key="adapter"
                                    :value="adapter"
                                    v-slot="{ active, selected }"
                                >
                                    <li
                                        :class="[
                                            selected || active ? 'bg-gray-100' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-4 pr-2',
                                        ]"
                                        @click="
                                            emit('update:button', adapter);
                                            cylinder.smartlock = false;
                                        "
                                        :hidden="cylinder.b == 10 || !adapter.is_active"
                                    >
                                        <div
                                            :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']"
                                            class="flex items-center"
                                        >
                                            <img
                                                :src="adapter.image.src"
                                                v-if="adapter.image"
                                                class="h-12 inline-block mr-4"
                                            />
                                            <div class="h-12" v-else-if="!wizard"></div>
                                            <span>
                                                <span class="text-gray-700 ml-1 block">{{ adapter.value }}</span>
                                                <span
                                                    class="text-gray-500 ml-1 block text-xs"
                                                    v-if="adapter.value != 'Geen knop' && adapter.value != 'Geen'"
                                                    >op {{ cylinder.b }}mm</span
                                                >
                                            </span>

                                            <span class="grow text-right">
                                                <span
                                                    v-if="adapter.price > 0"
                                                    class="rounded px-2 py-1 text-xs bg-gray-200 ml-2"
                                                    >+ {{ price(adapter.price) }}</span
                                                >
                                            </span>
                                        </div>
                                    </li>
                                </ListboxOption>
                            </div>
                        </div>

                        <div v-if="cylinder.b == 10" class="px-6 py-5 w-fit text-sm text-gray-500">
                            <span v-if="wizard">Geen knop mogelijk op binnenzijde 10mm.</span>
                            <span v-else>Geen knop mogelijk op binnenzijde 10mm.</span>
                        </div>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
const inBrowser = typeof window !== 'undefined';

const props = defineProps({
    options: Object,
    smartlock_adapters: Object | Boolean,
    button: Object | Boolean,
    cylinder: Object,
    info: String,
    wizard: Boolean,
    mks: Boolean,
    index: Number,
    categoryFilter: Boolean,
    disabled: Boolean,
});
const selected = ref(props.button != false ? props.button : props.options[0]);
const infoAdapters = 'De smart lock adapter is het verbindingsstuk tussen je cilinderslot en de smart lock. Met de adapter monteer je de smart lock eenvoudig en direct op je m&c cilinders.';

watch(
    () => props.cylinder,
    (newValue, oldValue) => {
        if (newValue.b == 10) {
            selected.value = props.options[0];
            newValue.button = props.options[0];
        }

        // Change button color
        let color = colorCode(newValue.color);

        if (newValue.color && color != newValue.button.color) {

            props.options.forEach(function (element, key) {
                if (element.value == newValue.button.value && color == element.color) {
                    selected.value = props.options[key];
                    newValue.button = props.options[key];
                }
            });
        }
    },
    { deep: true }
);


const filteredOptions = computed(() => {
    let color = colorCode(props.cylinder.color);

    return props.options.filter(function name(option) {
        if (!option.color) {
            return true;
        }
        return option.color == color;
    });
})


let emit = defineEmits(['showModalSmartlock','update:button']);

if (inBrowser) {
    window.mitt.on('selectSmartlock', (data) => {

        if (props.index == data.cylinder) {
            data.smartlock.image = data.smartlock.option.image
            selected.value = data.smartlock;
            props.cylinder.button = data.smartlock;
            props.cylinder.smartlock = true;
        } 
    });
}

function removeSmartlock() {
    selected.value = props.options[0];
    props.cylinder.button = props.options[0];
    props.cylinder.smartlock = false;
}

function showModalSmartlock(index) {
    emit('showModalSmartlock',index);
    document.getElementById('closebtn' + props.index).click();
}

function colorCode(colorAttribute) {

    let color = 1;

    if (colorAttribute && colorAttribute.value == 'Messing') {
        color = 2;
    }

    if (colorAttribute && colorAttribute.value == 'Zwart') {
        color = 3;
    }

    return color;
}
</script>
