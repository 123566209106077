<template>
    <div class="text-center">
        <div class="flex flex-wrap">
            <div class="my-6 drag-area p-2 rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-200 w-full md:w-1/2 relative">
                <div class="border border-dashed border-gray-400 rounded-lg p-6">
                    <div class="pb-2">
                        <fa :icon="['fas', 'cloud-upload']" class="h-8 text-gray-300" />
                    </div>
                    <header class="text-lg my-4">Sleep bestand om te uploaden</header>
                    <span class="block mb-4 divider">Of</span>
                    <button class="button dgreen mx-auto mb-2">Selecteer bestand</button>
                    <div class="image mx-auto flex justify-center" v-if="data.result.succeed"></div>

                    <input type="file" class="file-input" @change="fileUpload()" @drop="fileUpload()" ref="files" />

                    <div class="flex items-center mt-4" v-if="loading">
                        <fa :icon="['fad', 'spinner-third']" class="text-dgreen opacity-80 mr-2 fa-spin" />
                        <span>Uploaden...</span>
                    </div>

                    <div class="flex items-center mt-4" v-if="data.result.succeed">
                        <fa :icon="['fas', 'check-circle']" class="text-green-600 mr-2" />
                        <span> Bestand succesvol geüpload </span>
                    </div>

                    <div class="text-center mt-4" v-if="data.result.error">
                        <fa :icon="['fas', 'exclamation-triangle']" class="text-red-600 mr-2" />
                        <span class="block font-bold">Upload mislukt.</span>
                        <span class="block">{{ data.result.message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';

// Props definition
defineProps(['file']);
const emit = defineEmits(['update:file']);

// Reactive state
const files = ref();
const loading = ref(false);

const data = reactive({
    file: false,
    uploaded: false,
    result: {
        succeed: false,
        error: false,
    },
});

// Methods
const fileUpload = () => {
    loading.value = true;

    setTimeout(() => {
        let uploadedFiles = files.value.files;
        data.uploaded = true;
        data.file = uploadedFiles[0];
        startUpload();
    }, 300);
};

const startUpload = () => {

    const formData = new FormData();
    formData.append('file', data.file);

    axios
        .post('/winkelwagen/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((result) => {
            data.result = result.data;
            emit('update:file', data.result.file);
        })
        .catch((error) => {
            data.result = {
                error: true,
                message: error.response?.data?.message || 'Er is een fout opgetreden tijdens het uploaden.',
            };
            data.file = false;  
            failed();
        })
        .finally(() => {
            loading.value = false;
        });
};

const failed = () => {
    const dropArea = document.querySelector('.drag-area');
    const dragText = dropArea.querySelector('.drag-area header');
    dragText.textContent = 'Opnieuw uploaden';
};

// Mounted logic moved to onMounted hook
onMounted(() => {
    const dropArea = document.querySelector('.drag-area');
    if (!document.body.contains(dropArea)) return;

    const dragText = dropArea.querySelector('.drag-area header');
    const button = dropArea.querySelector('.drag-area button');
    const input = dropArea.querySelector('.drag-area input');
    const image = dropArea.querySelector('.drag-area .image');
    const divider = dropArea.querySelector('.drag-area .divider');
    let file;

    const showFile = () => {
        let fileType = file.type;
        let validExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/pdf'];
        if (validExtensions.includes(fileType)) {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                let fileURL = fileReader.result;
                let imgTag = `<img src="${fileURL}" alt="image" class="h-32">`;
                if (image) {
                    image.innerHTML = imgTag;
                }
            };
            fileReader.readAsDataURL(file);
        }
    };

    button.onclick = () => {
        input.click();
    };

    input.addEventListener('change', function () {
        file = this.files[0];
        dragText.textContent = file.name;
        dropArea.classList.add('active');
        button.textContent = 'Ander bestand selecteren';
        divider.classList.add('hidden');
        showFile();
    });

    dropArea.addEventListener('dragover', (event) => {
        event.preventDefault();
        dropArea.classList.add('active');
        dragText.textContent = 'Laat los om te uploaden';
    });

    dropArea.addEventListener('dragleave', () => {
        dropArea.classList.remove('active');
        dragText.textContent = 'Sleep bestand om te uploaden';
    });

    dropArea.addEventListener('drop', (event) => {
        event.preventDefault();
        file = event.dataTransfer.files[0];
        input.files = event.dataTransfer.files;
        dragText.textContent = file.name;
        dropArea.classList.add('dropped');
        button.textContent = 'Ander bestand selecteren';
        divider.classList.add('hidden');
        showFile();
    });
});
</script>

<style>
.file-input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
}

.drag-area.active {
    border: 1px solid rgba(5, 150, 105, var(--tw-border-opacity));
    background: #fff;
    transition: background-color 0.5s ease;
}
</style>
