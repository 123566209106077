<template>
    <div id="trengo-widget-fake-mobile z-60" class="hidden-desktop" @click="loadChat">
        <div class="fixed bottom-6 right-4 z-50 transition-all" id="widget-fake-mobile">
            <div
                class="h-16 w-16 bg-dgreen opacity-100 group transform transition-scale ease-in-out duration-200 px-4 p-2 rounded-3xl shadow-overallxl cursor-pointer z-50 flex items-center justify-center"
            >
                <fa :icon="['fad', 'comments']" class="text-white w-10 h-10 mx-auto group-hover:scale-110 transition-all" />
            </div>
        </div>
    </div>
</template>

<script setup>
function loadChat() {
    window.mitt.emit('open-chat')
    window.mitt.emit('mobile-chat-opened')
}
</script>
