<template>
    <div class="inline-block">
        <div class="cursor-pointer group" @click="showModal()">
            <slot></slot>
            <fa v-if="infoIcon" :icon="['fas', 'info-circle']" class="ml-2 text-dgreen2 w-4 h-4 inline-block group-hover:text-blue-700 group-hover:font-bold" />
            <span v-else-if="!hideIcon" class="link font-normal" :class="text ? 'block' : 'ml-2 text-sm'">{{ text ?? 'Uitleg' }}</span>
        </div>

        <teleport to="#modals">
            <modal :body="content" :title="title" ref="modal">
                <strong>{{ title }}</strong>
                <div v-html="content"></div>
            </modal>
        </teleport>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    props: {
        title: String,
        body: String | Boolean,
        text: String | Boolean,
        link: String | Boolean,
        hideIcon: Boolean,
        infoIcon: Boolean,
    },

    data() {
        return {
            content: false,
        };
    },

    methods: {
        showModal() {
            let self = this
            
            if (this.link && !this.content) {
                axios.get(this.link).then(function (response) {
                    self.content = response.data;
                });
            }

            this.$refs.modal.open = true;
        },
    },

    created() {
        if (this.body) {
            this.content = this.body;
        }
    },
};
</script>
