import {
    faBars,
    faTimes,
    faShoppingCart,
    faSearch,
    faCheck,
    faAngleDown,
    faAngleUp,
    faCheckCircle,
    faThumbsUp,
    faPlus,
    faUser,
    faTruck,
    faTruckFast,
    faChevronRight,
    faChevronLeft,
    faTags,
    faCopy,
    faClock,
    faMinus,
    faTrashAlt,
    faKey,
    faCog,
    faLevelDown,
    faInfoCircle,
    faPencil,
    faEdit,
    faUndo,
    faEnvelope,
    faAngleRight,
    faAngleLeft,
    faHeart,
    faArrowLeftLong,
    faCreditCard,
    faQuestionCircle,
    faPhone,
    faComments,
    faMoneyCheckDollar,
    faFileArrowDown,
    faListCheck,
    faMagnifyingGlass,
    faSignIn,
    faFilterList as falFilterList,
    faLocationDot,
    faBuildingCircleCheck,
    faHouseLock,
    faFaceSmile,
    faCloudUpload,
    faStar as falStar,
    faCameraRetro,
    faArrowUpRightFromSquare,
    faExternalLink,
    faCookieBite,
    faClipboardListCheck,
    faArrowsLeftRightToLine,
    faArrowsToLine,
    faUserHelmetSafety,
    faMemoCircleCheck,
    faPenRuler,
} from '@fortawesome/pro-light-svg-icons';

import {
    faCircleDot as fadCircleDot,
    faCircleHalf as fadCircleHalf,
    faSpinnerThird,
    faUser as fadUser,
    faComments as fadComments,
    faFloppyDisk as fadFloppyDisk,
    faStarHalf as fadStarHalf,
} from '@fortawesome/pro-duotone-svg-icons';

import {
    faStar as fasStar,
    faStarHalf as fasStarHalf,
    faCircle as fasCircle,
    faInfoCircle as fasInfoCircle,
    faTags as fasTags,
    faTrash as fasTrash,
    faCopy as fasCopy,
    faKey as fasKey,
    faCloudUpload as fasCloudUpload,
    faPlusCircle,
    faMinusCircle,
    faExclamationTriangle,
    faShieldBlank,
    faUser as fasUser,
    faMedal as fasMedal,
    faCirclePlay,
    faCircleCheck as fasCheckCircle,
    faGift as fasGift,
    faAngleDown as fasAngleDown,
    faFilterList as fasFilterList,
    faShieldCheck as fasShieldCheck,
    faFileSpreadsheet as fasFileSpreadsheet,
    faDoorOpen as fasDoorOpen,
    faDoorClosed as fasDoorClosed,
    faFloppyDisk,
    faUsers,
    faSignOut,
    faTable,
    faComments as fasComments,
    faEnvelope as fasEnvelope,
    faPhone as fasPhone,
    faClipboardListCheck as fasClipboardListCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faInstagram, faWhatsapp, faPinterest, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
    faUser,
    fasUser,
    fadUser,
    faBars,
    faTimes,
    faShoppingCart,
    faSearch,
    faCheck,
    fasStar,
    fasStarHalf,
    fadStarHalf,
    fasCircle,
    faAngleDown,
    faAngleUp,
    faCheckCircle,
    faThumbsUp,
    faFacebook,
    faInstagram,
    faPlus,
    faChevronRight,
    faChevronLeft,
    fadCircleDot,
    fasInfoCircle,
    faInfoCircle,
    faTags,
    fasTags,
    fasTrash,
    faCopy,
    fasCopy,
    faClock,
    faKey,
    fasKey,
    faMinus,
    fasCloudUpload,
    faTrashAlt,
    faCog,
    faLevelDown,
    faSpinnerThird,
    faExclamationTriangle,
    faPencil,
    faEdit,
    faUndo,
    faPlusCircle,
    faMinusCircle,
    faShieldBlank,
    faTruck,
    faTruckFast,
    faUserHelmetSafety,
    faMemoCircleCheck,
    faPenRuler,
    fasMedal,
    faAngleRight,
    faHeart,
    faCirclePlay,
    faArrowLeftLong,
    fasCheckCircle,
    faAngleLeft,
    fasGift,
    faCreditCard,
    faQuestionCircle,
    faPhone,
    faComments,
    faMoneyCheckDollar,
    fadCircleHalf,
    fasAngleDown,
    fasFilterList,
    faUsers,
    fasShieldCheck,
    fasFileSpreadsheet,
    fasDoorOpen,
    fasDoorClosed,
    faFloppyDisk,
    fadFloppyDisk,
    faTable,
    faFileArrowDown,
    faListCheck,
    faMagnifyingGlass,
    faSignIn,
    falFilterList,
    faWhatsapp,
    faLocationDot,
    faBuildingCircleCheck,
    faHouseLock,
    faFaceSmile,
    faCloudUpload,
    faPinterest,
    faYoutube,
    faEnvelope,
    falStar,
    faCameraRetro,
    faArrowUpRightFromSquare,
    faSignOut,
    faExternalLink,
    faLinkedin,
    fasComments,
    fasEnvelope,
    fasPhone,
    faCookieBite,
    faClipboardListCheck,
    fasClipboardListCheck,
    fadComments,
    faArrowsLeftRightToLine,
    faArrowsToLine,
);
